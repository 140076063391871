import { Vehicle } from '@fc-core';

export interface EventHistoryItem {
  id: number;
  created: string;
  modified: string;
  context: Context;
  employee: string;
  read: boolean;
  readAt: string | null;
}

export interface Context {
  def: EventDef;
  vehicle: Vehicle;
  LocationCondition?: LocationCondition;
  MovementTypeCondition?: MovementTypeCondition;
  VehicleSpeedCondition?: VehicleSpeedCondition;
}

export interface EventDef {
  id: string;
  name: string;
  description: string;
}

export interface LocationCondition {
  entered?: string;
  exited?: string;
  locationId: number;
}

export interface MovementTypeCondition {
  ts: string;
  current: number;
  previous: number;
}

export interface VehicleSpeedCondition {
  maxSpeed: number;
  currentSpeed: number;
}

export enum MovementType {
  Stopped = 1,
  Idling = 2,
  Traveling = 3,
  Towed = 4,
}
